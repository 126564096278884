<template>
	<div class="main-content">
		<nav id="nav">
			<img
				height="100"
				alt="logo"
				src="./assets/images/logo_panasonic_bluebg.svg"
			/>
		</nav>
		<div class="page-content">
			<div class="container-fluid">
				<router-view />
			</div>
		</div>
	</div>
</template>
