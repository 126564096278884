import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import BootstrapVue3 from "bootstrap-vue-3";
import appScss from './assets/scss/app.scss';
import useVuelidate from "@vuelidate/core";
import { useCookies } from "vue3-cookies";

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "devextreme/dist/css/dx.light.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import airModule from "./modules/air";

import { registerModules } from "./register-modules";
registerModules({
  air: airModule
});

library.add(fas);

const app = createApp(App);

app.use(router);
app.use(store);
app.use(useCookies);
app.use(BootstrapVue3);
app.use(appScss);
app.use(useVuelidate);
app.component("fas", FontAwesomeIcon);
app.mount("#app");

