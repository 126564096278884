import axios from "axios";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

class authService {
	async login(username, password) {
		let body = { username: username, password: password };
		let response = await axios.post(
			"https://iaq.ams-coldchain-prdcsg.com/user/login",
			body
		);
		if (response.data.status == 401) {
			return response.message;
		}
		let result = { token: response.data.data.token };
		axios.defaults.headers.common["Authorization"] = result.token;
		await localStorage.setItem("user", JSON.stringify(result));
		return result;
	}

	logout() {
		localStorage.removeItem("user");
		cookies.remove("token");
		return true;
	}
}
export default new authService();
